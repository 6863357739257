<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event.target.value)"
    max-width="90vw"
    persistent
  >
    <div style="position: relative; width: 100%">
      <video
        ref="videoHolder"
        style="
          display: block;
          max-width: 100%;
          min-width: 100%;
          width: auto !important;
          height: auto !important;
        "
      />
      <v-btn
        @click="$emit('input', false)"
        color="error"
        style="position: absolute; bottom: 20px; right: 20px; z-index: 999"
      >
        Close
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!@/../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
  name: "qrScan",
  props: ["value", "timeoutLen"],
  data() {
    return {
      qrScannerInstance: null,
      justRead: null,
      readTime: 0,
      timeoutDefault: 3000,
    };
  },
  computed: {
    timeout() {
      if (this.timeoutLen) {
        return this.timeoutLen;
      } else {
        return this.timeoutDefault;
      }
    },
  },
  methods: {
    async handleQrRead(data) {
      if (data == "") {
        return;
      }
      let readAt = new Date().getTime();
      if (
        this.justRead == data &&
        readAt - this.readTime < this.timeout &&
        this.readTime != 0
      ) {
        return;
      } else {
        this.justRead = data;
        this.readTime = readAt;
      }
      this.$emit("scan", data);
    },
  },
  mounted() {
    this.qrScannerInstance = new QrScanner(
      this.$refs.videoHolder,
      this.handleQrRead
    );
    this.qrScannerInstance.start();
  },
  destroyed() {
    if (this.qrScannerInstance != null) {
      this.qrScannerInstance.stop();
      this.qrScannerInstance.destroy();
      this.qrScannerInstance = null;
    }
  },
};
</script>
