<template>
  <div>
    {{ displayData }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBit: "",
      displayData: "Loading...",
    };
  },
  computed: {
    uncovered() {
      let data = this.$store.getters["awsRegistered/uncovered"];
      if (!data.loaded) {
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
  },
  methods: {
    async getUncoveredLetter() {
      let letterData = JSON.parse(
        JSON.stringify(await this.$store.dispatch("awsRegistered/getLetter"))
      );
      let indices = Object.keys(this.uncovered.byIndex);
      for (let i = 0; i < indices.length; i++) {
        indices[i] = indices[i] - 1;
      }
      for (let i = 0; i < letterData.length; i++) {
        if (!indices.includes(i)) {
          letterData[i] = letterData[i].replace(/./gi, "_");
        }
      }
      return letterData.join("");
    },
  },
  watch: {
    async uncovered(newVal) {
      if (newVal.dates.length) {
        this.displayData = await this.getUncoveredLetter();
      }
    },
  },
  async mounted() {
    if (this.uncovered.dates.length) {
      this.displayData = await this.getUncoveredLetter();
    }
  },
};
</script>
