<template>
  <div v-if="loggedIn">
    <v-row class="flex-grow-0">
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-qrcode-scan"
          color="green"
          @click="showQRScanner = true"
          v-ripple
        >
          Scan a Penny
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-message-text-outline"
          color="info"
          @click="readingLetter = true /* TODO should be a function probably */"
          v-ripple
          :disabled="!readLetterButton"
        >
          Read Message
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-speedometer"
          color="purple lighten-1"
          @click="statsDialog = true"
          v-ripple
        >
          Stats
        </tileCard>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <tileCard
          icon="mdi-frequently-asked-questions"
          color="red"
          @click="faqDialog = true"
          v-ripple
        >
          Support
        </tileCard>
      </v-col>
    </v-row>
    <qr-scanner
      v-if="showQRScanner"
      v-model="showQRScanner"
      @scan="handleScan"
    />
    <!-- TODO @read-letter should be a function - if it's totally uncovered, we should probably open a new component instead of just a small dialog -->
    <scan-snackbar
      v-model="snackbarVisible"
      :readLetter="readLetterButton"
      @read-letter="readingLetter = true"
      :revealOnScan="revealOnScan"
      :hasRevealed="hasRevealed"
      :letterPortion="letterPortion"
      :letterIndex="letterIndex"
      :timeout="snackbarTimeout"
      :final="final"
    />
    <v-dialog v-model="readingLetter" v-if="readLetterButton">
      <v-card>
        <v-container fluid>
          <read-letter-component />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="readingLetter = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="faqDialog">
      <v-card style="background-color: #f1f1f1 !important">
        <v-container fluid>
          <faq />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="faqDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statsDialog" v-if="loggedIn">
      <v-card
        :style="`${statsDialogHeight} height: 100%; overflow-y: scroll; overflow-x: hidden; background-color: #f1f1f1 !important; position: relative;`"
        class="heightDialog"
      >
        <v-container fluid>
          <stats
            :internalHeight.sync="maxHeight"
            v-if="statsDialog"
            :dialogStatus="statsDialog"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="statsDialog = false" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.heightDialog {
  transition-property: max-height, min-height;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
</style>

<script>
import tileCard from "@/components/tileCard.vue";
import qrScanner from "@/components/qrScanner";
import scanSnackbar from "@/components/scanSnackbar.vue";

import stats from "@/components/stats.vue";
import readLetterComponent from "@/views/readLetter.vue";
import faq from "@/views/HomeFAQ.vue";

export default {
  name: "UserHome",
  data() {
    return {
      loggedIn: false,
      showQRScanner: false,
      snackbarVisible: false,
      snackbarTimeout: undefined,
      hasRevealed: false,
      rawLetterIndex: 0,
      readingLetter: false,
      final: false,
      letterPortionOverride: undefined,
      statsDialog: false,
      rawLetterPortion: "",
      maxHeight: "48px",
      faqDialog: false,
    };
  },
  methods: {
    async handleScan(data) {
      this.letterPortionOverride = undefined;
      this.letterIndex = 0;
      this.snackbarTimeout = -1;
      this.snackbarVisible = "true" + new Date().getTime();
      try {
        let index = Number(data);
        if ("" + index != data) {
          // Probably not a tag.
          throw { customError: -1 };
        }
        let response = await this.$store.dispatch(
          "awsRegistered/uncover",
          index
        );
        if (response.status == "error") {
          if (response.message.includes("belong")) {
            throw { customError: -1 };
          } else if (response.message.includes("max")) {
            throw { customError: -2 };
          } else if (response.message.includes("start")) {
            throw { customError: -3 };
          }
        } else {
          this.hasRevealed = response.status == "warning";
          this.final = response.last;
          this.letterIndex = index;
        }
      } catch (err) {
        if (Object.prototype.hasOwnProperty.call(err, "customError")) {
          this.letterIndex = err.customError;
        } else {
          // we're done here.
          this.letterIndex = -4;
          this.letterPortionOverride = err;
        }
      }
      this.snackbarTimeout = undefined;
      this.snackbarVisible = "true" + new Date().getTime();
    },
  },
  computed: {
    statsDialogHeight() {
      return `max-height: min(90vh, calc(${this.maxHeight} + 76px)); min-height: min(90vh, calc(${this.maxHeight} + 76px));`;
    },
    letterIndex: {
      get() {
        return this.rawLetterIndex;
      },
      async set(newVal) {
        this.rawLetterIndex = newVal;
        if (newVal > 0) {
          this.rawLetterPortion = (
            await this.$store.dispatch("awsRegistered/getLetter")
          )[newVal - 1];
        }
      },
    },
    letterPortion() {
      if (this.letterPortionOverride) {
        return this.letterPortionOverride;
      } else if (!this.revealOnScan || this.letterIndex < 1) {
        return "";
      } else {
        return this.rawLetterPortion;
      }
    },
    userData() {
      let ud = this.$store.getters["awsRegistered/userData"];
      if (ud === false) {
        this.$store.dispatch("awsRegistered/getUserData");
        this.$store.commit("awsRegistered/updateGettingUserData", true);
        return true;
      } else {
        return ud;
      }
    },
    readLetter() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.readLetter;
      }
      return false;
    },
    revealOnScan() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.revealOnScan;
      }
      return false;
    },
    readLetterButton() {
      return (
        this.readLetter ||
        (Object.keys(this.uncovered.byIndex).length == this.letterDataLength &&
          this.letterDataLength)
      );
    },
    uncovered() {
      let data = this.$store.getters["awsRegistered/uncovered"];
      if (!data.loaded) {
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
    letterDataLength() {
      if (this.$store.getters["awsRegistered/letterLength"] < 0) {
        if (this.$store.getters["awsRegistered/letterLength"] == -2) {
          this.$store.commit("awsRegistered/updateLetterLength", -1);
          this.$store.dispatch("awsRegistered/getLetter");
        }
        return 0;
      } else {
        return this.$store.getters["awsRegistered/letterLength"];
      }
    },
  },
  async mounted() {
    let self = this;
    await Promise.all([
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!self.$store.hasModule("encryption")) {
          let encryption = (await import("@/store/encryption.js")).default;
          self.$store.registerModule("encryption", encryption);
        }
        resolve();
      }),
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        if (!self.$store.hasModule("awsRegistered")) {
          let awsRegistered = (await import("@/store/awsRegistered.js"))
            .default;
          self.$store.registerModule("awsRegistered", awsRegistered);
        }
        resolve();
      }),
    ]);
    self.$store.commit("addLoading", "Logging In");
    let [unProto, pw] = self.$store.getters["util/unscramble"](
      self.$store.getters["util/unreplace"](self.$route.params.login)
    );
    let un = self.$store.getters.formatUUID(unProto);
    await self.$store.dispatch("awsRegistered/updateLogin", { un, pw });
    let { userData } = await self.$store.dispatch(
      "awsRegistered/getUserData",
      true
    ); // the 'true' is a 'force' parameter that runs an ensured reset to get the user data.
    self.$store.getters.localStore(
      "pennyLogins",
      `${self.$store.getters.dateString(
        userData.startDate
      )} - ${self.$store.getters.dateString(userData.endDate)}`,
      // userData.username,
      self.$route.params.login
    );
    self.$store.commit("removeLoading", "Logging In");
    self.loggedIn = true;
  },
  components: {
    tileCard,
    qrScanner,
    scanSnackbar,
    stats,
    readLetterComponent,
    faq,
  },
};
</script>
